<template>
  <v-container text-xs-center fluid>
    <v-card elevation="0" flat>
      <v-layout row wrap>
        <v-flex xs12 sm12 md12 style="padding: 2%;">
          <v-card>
            <v-card-title>
              <v-icon large>mdi-headphones-settings</v-icon>
              <span class="title" style="padding-left: 5px">
                Call Recording</span
              >
            </v-card-title>
            <v-card-text>
              <div align="center">
                <h2 style="padding-bottom: 1.5%;"></h2>
              </div>
              <div align="center">
                GoDial can automatically sync call recordings for your calls.
                GoDial works on Android phones which support call recording by
                default. After each call GoDial will access the recording folder
                of the device and try to sync the file. However, some phones do
                not have inbuilt call recording, and some phones which have it
                may not make the folder available to other apps ( Such as some
                MI phones), in these cases we cannot sync the recordings. If you
                think you have a phone where recording works and the files are
                accessible from the file manager, yet GoDial does not sync it,
                kindly send us an email with your device details and the path to
                recording at support@godial.cc. Please enable recording here and
                also at agent's setting page to ensure call recordings are
                enabled.
              </div>

              <v-list-item class="recordPadding">
                <v-list-item-icon> </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon style="padding:2%">mdi-play-circle-outline</v-icon
                    >Enable Recordings
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-switch
                    v-model="recordCallsWithTrueCaller"
                    class="mt-5"
                    inset
                    @change="updateRecordCalls"
                  ></v-switch>
                </v-list-item-action>
              </v-list-item>

              <v-alert type="info"
                >Call Recording is a free feature, we do not charge you at the
                moment for this feature. It is experimental and does not work on
                all phones.</v-alert
              >
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex xs12 sm12 md12 style="padding: 1%">
          <v-card>
            <v-card-title>
              <v-icon large>mdi-file</v-icon>

              <v-row justify="end">
                <v-col style="padding-top: 40px;"> Files</v-col>
                <!-- <v-col>Total Usage</v-col> -->
                <v-col>
                  Total Usage
                  <h3 v-if="$store.getters.companyDetails.usage < 1024">
                    {{
                      $store.getters.companyDetails.usage == "null" ||
                      $store.getters.companyDetails.usage == 0
                        ? mathfloorCustom(0)
                        : mathfloorCustom($store.getters.companyDetails.usage)
                    }}
                    /
                    {{
                      mathfloorCustom(
                        $store.getters.companyDetails.plan.storageLimit
                      )
                    }}
                  </h3>
                  <h3 v-else>
                    {{ $store.getters.companyDetails.usage / 1024 }}GB /
                    {{
                      Math.round(
                        $store.getters.companyDetails.plan.storageLimit / 1024
                      )
                    }}GB
                  </h3>

                  <v-progress-linear
                    color="light-green "
                    height="20"
                    striped
                    :value="
                      ($store.getters.companyDetails.usage /
                        $store.getters.companyDetails.plan.storageLimit) *
                        100
                    "
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="fileTableHeaders"
                :items="files"
                :loading="loading"
                :items-per-page="records"
                item-key="id"
                hide-default-footer
              >
                <template v-slot:item.downlaod="{ item }">
                  <v-btn
                    icon
                    color="primary"
                    @click="download(item.name, 'phone')"
                  >
                    <v-icon>cloud_download</v-icon>
                  </v-btn>
                </template>

                <template v-slot:item.delete="{ item }">
                  <v-btn icon color="error" @click="removeRecording(item.id)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
              <v-divider style="margin-bottom: 10px"></v-divider>
            </v-card-text>
            <v-card-text>
              <div class="v-data-footer">
                <div class="v-data-footer__select">
                  Rows per page:
                  <div
                    class="v-input v-input--hide-details v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-select"
                  >
                    <v-select
                      v-model="records"
                      :items="recordsPerPage"
                      style="margin: 5px"
                      @change="pagination"
                    ></v-select>
                  </div>
                </div>
                <div class="v-data-footer__select">
                  <div class="v-data-footer__icons-before">
                    <v-btn
                      :disabled="page == 1 ? true : false"
                      color="primary"
                      fab
                      small
                      text
                      @click="prevSet"
                    >
                      <v-icon :size="24">arrow_left</v-icon>
                    </v-btn>
                  </div>
                  <div class="v-data-footer__pagination">
                    {{ `${page}/${pageCount} of ${totalRecords}` }}
                  </div>
                  <div class="v-data-footer__icons-after">
                    <v-btn
                      :disabled="page >= pageCount ? true : false"
                      color="primary"
                      fab
                      small
                      text
                      @click="nextSet"
                    >
                      <v-icon :size="24">arrow_right</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex xs12 sm12 md12 style="padding: 1%">
          <v-card>
            <v-card-title>
              <v-icon large>mdi-database-plus</v-icon>
              <span class="title" style="padding-left: 5px"
                >Upgrade Storage</span
              >
            </v-card-title>
            <v-card-text>
              <div>
                <v-layout row wrap style="margin: 0px">
                  <v-flex xs12 sm12 md12 style="padding: 0%">
                    <v-container justify="center">
                      <h6
                        class="title font-weight-light"
                        style="margin-top: 5px; text-align: center"
                      >
                        <!-- <h5 class="title font-weight-light">
                          {{ Math.round(addStorage / 10) }}GB
                        </h5> -->
                        Please contact us at support@godial.cc
                      </h6>
                      <v-layout row wrap style="padding-bottom: 4%;">
                        <v-flex xs12 sm12 m12> </v-flex>
                      </v-layout>

                      <v-layout style="justify-content: center">
                        <v-btn color="green" style="color: white"
                          ><a
                            href="mailto:support@godial.cc"
                            style="color: white;text-decoration:none;"
                            >Contact Us</a
                          ></v-btn
                        >
                      </v-layout>
                      <!-- <v-layout style="justify-content: center">
                        <v-btn
                          color="green"
                          style="color: white"
                          :disabled="true"
                          @click="upgradeStorage"
                          >Upgrade
                        </v-btn>
                      </v-layout> -->
                    </v-container>
                  </v-flex>
                </v-layout>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12 sm12 md12 style="padding: 2%;">
          <!-- <v-card>
            <v-card-text>
              <span class="title" style="padding-left: 5px">
                <b>
                  How it works
                </b>
              </span>
              <div align="center">
                <h2 style="padding-bottom: 1%;"></h2>
              </div>
              <div align="left">
                GoDial can automatically sync call recordings for your calls. To
                use this feature ensure that Truecaller is installed and Call
                Recording is working in Truecaller.
              </div>

              <div align="center">
                <h2 style="padding-bottom: 1%;"></h2>
              </div>

              <span class="title" style="padding-left: 5px">
                <b>
                  Limitations and Troubleshooting
                </b>
              </span>
              <div align="center">
                <h2 style="padding-bottom: 1%;"></h2>
              </div>
              <div align="left">
                GoDial can automatically sync call recordings for your calls. To
                use this feature ensure that Truecaller is installed and Call
                Recording is working in Truecaller.
              </div>
            </v-card-text>
          </v-card> -->

          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                ><b
                  >How do I enable call recordings?</b
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                To enable call recordings enable it in the agent/member's
                setting ( Team and Member page) and also enable it in the
                recording page (above). Once enable the agent/member may be
                required to re login to the application.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                ><b>Which phones work?</b></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                Most phones that support call recording by default will work.
                Some phones may be recording the close by storing it at an
                unaccessible location (for example MI Android 12 devices). We do
                not provide individual level support for phones, incase you may
                think your device should work, send us an email at
                support@godial.cc with your device details and path to
                recording.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                ><b
                  >Why does it not work on all phones</b
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                Since GoDial is a SIM based dialer, we rely on your phone to
                make the calls (hence saving you thousands in cloud calling
                charges), but by doing this we have to rely on the device to
                record the calls. iOS does not have any call recording at all,
                while some Android phones still have it.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                ><b
                  >Call recording is a mandatory feature for me, it should work
                  flawlessly on all phones</b
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                Incase it is a must need feature, you will need to shell out
                2-4x the costs and get a cloud telephony integration (exotel,
                knowlarity, twilio, vonage, etc) done with GoDial, so that calls
                are placed through these providers. Reach out to us at
                support@godial.cc, incase your interested. We will also start
                offering our integrated cloud packages very soon.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                ><b
                  >For how long are recordings stored?</b
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                Recordings are available till your account is active, once your
                account has expired recordings will be deleted after 60 days.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                ><b
                  >How much will we be charged for storage?</b
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                Currently our pricing for storage is not yet decided since this
                is an experimental feature, Incase you reach your limit, let us
                know at support@godial.cc
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                ><b>Where can I access recordings?</b></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                Recordings can be played from Master report on each call log and
                all recording files are listed on Recording page (above)
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                ><b
                  >My internal storage is getting full</b
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                Kindly delete your recordings from the recording folder every
                few days
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                ><b
                  >My phone has recording yet GoDial is not working?</b
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                We do not provide individual level support for phones, incase
                you may think your device should work, send us an email at
                support@godial.cc with your device details and path to
                recording.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                ><b>How to debug call recordings? </b></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                Please goto the Diagnostics page from the settings menu in the
                app, ensure all permissions are enabled, then click find path
                and see if the path to your recording folder has been determined
                and last few recording files are shown. If the path cannot be
                found and you think the device can record calls, please mail us
                with the path to your recording folder and email to
                support@godial.cc
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                ><b
                  >Which permissions are required for this to work?</b
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                File permissions are mandatory for call recording
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                ><b
                  >Is it legal to capture recordings?</b
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                It depends on your local law, since we as GoDial do not record
                the calls, we bear no legal responsibilities for it. The call
                records are only synced from your device to godial.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                ><b
                  >Will personal phone calls also be recorded?</b
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                Only numbers dialed or disposed through GoDial will be synced.
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      recordCallsWithTrueCaller: false,
      enableRecording: false,
      panel: [],
      addStorage: 0,
      loading: false,
      recordsPerPage: [1, 5, 15, 30, 60],

      page: 1,
      pageCount: 0,
      totalRecords: 0,
      records: 15,

      files: [],
      fileTableHeaders: [
        {
          text: "File Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Size", value: "size", sortable: false },
        { text: "Time", value: "createdOn", sortable: false },
        { text: "", value: "downlaod", sortable: false },
        { text: "", value: "delete", sortable: false },
      ],
    };
  },
  created() {
    this.fetchFiles();
    this.recordCallsWithTrueCaller = this.$store.state.companyDetails.recordCallsWithTrueCaller;
  },
  computed: {
    ...mapGetters(["ENDPOINT"]),
  },
  methods: {
    removeRecording(item) {
      console.log("item 123", item);

      this.$http
        .delete(`${this.ENDPOINT}/attachments/${item}`)
        .then((response) => {
          this.loader = false;
          this.fetchFiles();
          this.$swal({ type: "success", text: "Successfully deleted" });
          // this.close();
        })
        .catch((err) => {
          this.loader = false;
          this.$swal({ type: "error", text: "Something went wrong" });
        });
    },
    download(src, dialingOpt) {
      if (src != "") {
        if (dialingOpt == "exotel") {
          console.log(src);
          var a = document.createElement("a");

          a.href = src;
          a.setAttribute("download", src.toString());
          a.target = "_blank";
          a.click();
        } else {
          console.log(src);
          var au = document.createElement("a");

          au.href = `${this.$store.state.ENDPOINT}/storages/get/${src}?access_token=${this.$store.getters.user.token}`;
          au.setAttribute("download", src.toString());
          au.target = "_blank";
          au.click();
        }
      }
    },
    mathfloorCustom(value) {
      if (value == 0) {
        return "0MB";
      }

      var filterValueInBytes = value * 1024 * 1024;

      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      var i = parseInt(
        Math.floor(Math.log(filterValueInBytes) / Math.log(1024))
      );

      var finalValueData =
        Math.round(filterValueInBytes / Math.pow(1024, i), 2) + "" + sizes[i];

      return finalValueData;
    },
    nextSet() {
      this.page++;
      this.pagination();
    },
    prevSet() {
      this.page--;
      this.pagination();
    },
    async pagination() {
      try {
        this.pageCount = Math.ceil(this.totalRecords / this.records);
        if (this.page > this.pageCount) {
          this.page = 1; //set default
        }
        this.files = await this.fetchFiles(
          this.records,
          this.records * this.page - this.records
        );

        // Save default pagination of seperate pages
        this.paginate[
          this.$router.currentRoute.name
            .toString()
            .trim()
            .replace(/ /g, "")
            .toLowerCase()
        ] = {
          page: this.page,
          pageCount: this.pageCount,
          records: this.records,
          totalRecords: this.totalRecords,
        };
        this.$store.commit("setPagination", this.paginate);

        return;
      } catch (e) {
        this.loader = false;
      }
    },

    async fetchFiles(limit, skip) {
      if (typeof limit == "undefined" && typeof skip == "undefined") {
        try {
          var where = {
            companyId: this.$store.getters.user.companyId,
          };
          var response = await this.$http.get(
            this.$store.getters.ENDPOINT +
              "/attachments/count?where=" +
              encodeURIComponent(JSON.stringify(where))
          );

          this.totalRecords = response.body.count;
        } catch (e) {
          console.log(e);
        }
      }
      this.loading = true;

      this.files = [];
      if (limit == undefined || limit == undefined) {
        limit = 5;
        skip = 0;
      }
      var filter = {
        skip: skip,
        limit: limit,
        order: "createdOn DESC",
        where: {
          companyId: this.$store.getters.user.companyId,
          typeOfFile: "recording",
        },
      };

      var res = await this.$http.get(
        this.$store.getters.ENDPOINT +
          "/attachments?filter=" +
          encodeURIComponent(JSON.stringify(filter))
      );

      this.files = _.map(res.body, (c) => {
        c.size = this.mathfloorCustom(c.size);
        c.createdOn = moment(c.createdOn).format("lll");
        return c;
      });

      this.loading = false;
      return this.files;
    },

    upgradeStorage() {},
    updateRecordCalls() {
      var self = this;

      self.$http
        .patch(
          `${this.$store.getters.ENDPOINT}/companies/${self.$store.getters.user.companyId}`,
          { recordCallsWithTrueCaller: self.recordCallsWithTrueCaller }
        )
        .then((response) => {
          self.$store.dispatch("getCompanyDetails", {
            companyId: self.$store.getters.user.companyId,
          });
          console.log("response", response.body);
        })
        .catch((err) => {
          self.$swal({
            type: "error",
            text: "Something went wrong. Couldn't Update",
          });
        });
    },
  },
};
</script>

<style scoped>
.customMarginBottom {
  margin-bottom: 6px !important;
}

@media (min-width: 1200px) {
  .recordPadding {
    padding-left: 30%;
    padding-right: 30%;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .recordPadding {
    padding-left: 30%;
    padding-right: 30%;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .recordPadding {
    padding-left: 20%;
    padding-right: 20%;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .recordPadding {
    padding-left: 1%;
    padding-right: 1%;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .recordPadding {
    padding-left: 1%;
    padding-right: 1%;
  }
}
</style>
